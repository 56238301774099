import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Sayfa Bulunamadı" />
    <h1>SAYFA BULUNAMADI</h1>
    <p>Ahlar vahlar olsun ki yok böyle bi sayfa</p>
  </Layout>
)

export default NotFoundPage

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

NotFoundPage.defaultProps = {
  location: {
    pathname: '',
  },
};